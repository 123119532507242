import React, { useState, useMemo, useEffect } from 'react';
import { 
  Container, Grid, Card, CardContent, CardMedia, Typography, 
  Button, Chip, Avatar, Box, IconButton, TextField, InputAdornment,
  Skeleton, Divider, Paper, useTheme
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useInfiniteQuery } from '@tanstack/react-query';
import { motion, AnimatePresence } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import useDebounce from '../hooks/useDebounce';
import SearchIcon from '@mui/icons-material/Search';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import VisibilityIcon from '@mui/icons-material/Visibility';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import axios from 'axios';
import MetaTags from '../components/MetaTags';
import { getBlogSeo } from '../services/seoApi';

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
  [theme.breakpoints.up('md')]: {
    '&:hover': {
      transform: 'translateY(-4px)',
      boxShadow: theme.shadows[8],
    },
  }
}));

const OptimizedImage = React.memo(({ src, alt, height }) => {
  const [isLoaded, setIsLoaded] = React.useState(false);
  const imageRef = React.useRef(null);

  React.useEffect(() => {
    if (imageRef.current && 'loading' in HTMLImageElement.prototype) {
      imageRef.current.loading = 'lazy';
    }
  }, []);

  return (
    <Box sx={{ position: 'relative', height }}>
      <CardMedia
        component="img"
        height={height}
        image={src}
        alt={alt}
        ref={imageRef}
        onLoad={() => setIsLoaded(true)}
        sx={{ 
          objectFit: 'cover',
          opacity: isLoaded ? 1 : 0,
          transition: 'opacity 0.3s ease-in-out',
          borderRadius: { xs: 0, md: '4px 4px 0 0' }
        }}
      />
      {!isLoaded && (
        <Skeleton 
          variant="rectangular" 
          height={height}
          sx={{ 
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            borderRadius: { xs: 0, md: '4px 4px 0 0' }
          }} 
        />
      )}
    </Box>
  );
});

const CategoryChip = styled(Chip)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: 'white',
  margin: theme.spacing(0.5),
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const SearchField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: theme.shape.borderRadius * 3,
    transition: theme.transitions.create(['box-shadow']),
    '&:hover': {
      boxShadow: '0 0 0 1px rgba(0, 0, 0, 0.1)',
    },
    '&.Mui-focused': {
      boxShadow: `0 0 0 2px ${theme.palette.primary.main}25`,
    },
  },
}));

const FeaturedArticleCard = styled(Paper)(({ theme }) => ({
  position: 'relative',
  height: '400px',
  borderRadius: theme.shape.borderRadius * 2,
  overflow: 'hidden',
  marginBottom: theme.spacing(6),
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  display: 'flex',
  alignItems: 'flex-end',
  cursor: 'pointer',
  transition: 'transform 0.3s ease-in-out',
  '&:hover': {
    transform: 'scale(1.01)',
  },
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'linear-gradient(to top, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0.1) 100%)',
  },
}));

const CardContentStyled = styled(CardContent)({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  height: '280px', 
});

const CardDescriptionBox = styled(Box)({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
});

const Blog = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategory, setSelectedCategory] = useState(null);
  const debouncedSearch = useDebounce(searchTerm, 300);

  const [articles, setArticles] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const { ref: observerRef, inView } = useInView({
    threshold: 0.5,
  });

  const fetchArticles = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get('/api/articles', {
        params: {
          page: currentPage,
          limit: 9,
          category: selectedCategory || undefined
        }
      });

      console.log('Fetched articles:', response.data);

      const { articles, totalPages, hasMore } = response.data;
      
      if (currentPage === 1) {
        setArticles(articles || []);
      } else {
        setArticles(prev => [...prev, ...(articles || [])]);
      }
      
      setHasMore(hasMore);
      setTotalPages(totalPages);
    } catch (error) {
      console.error('Error fetching articles:', error);
      setArticles([]); 
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setCurrentPage(1);
    setArticles([]);
  }, [selectedCategory]);

  useEffect(() => {
    fetchArticles();
  }, [currentPage, selectedCategory]);

  useEffect(() => {
    if (inView && hasMore && !isLoading) {
      setCurrentPage(prev => prev + 1);
    }
  }, [inView, hasMore, isLoading]);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const getImagePath = (imagePath, size = 'preview') => {
    if (!imagePath) return '/api/articles/uploads/original/default-article.jpg';
    if (imagePath.startsWith('http')) return imagePath;
    return `/api/articles/uploads/${size}/${imagePath}`;
  };

  const handleArticleClick = (article) => {
    navigate(`/blog/${article.slug}`);
    window.scrollTo(0, 0);
  };

  const allCategories = useMemo(() => 
    [...new Set(articles.flatMap(article => article.categories))],
    [articles]
  );

  // Разделяем статьи на закрепленные и обычные для отображения
  const displayedArticles = useMemo(() => {
    if (!articles.length) return [];
    
    // На первой странице показываем закрепленные статьи только в начале
    if (currentPage === 1) {
      const pinnedArticles = articles.filter(article => article.isPinned);
      const regularArticles = articles.filter(article => !article.isPinned);
      return [...pinnedArticles, ...regularArticles];
    }
    
    // На остальных страницах показываем только незакрепленные
    return articles.filter(article => !article.isPinned);
  }, [articles, currentPage]);

  // Получаем первую статью для featured секции
  const featuredArticle = useMemo(() => {
    if (!articles.length) return null;
    // Если есть закрепленная статья, показываем её
    const pinned = articles.find(article => article.isPinned);
    return pinned || articles[0];
  }, [articles]);

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
        <Container maxWidth="lg" sx={{ pt: { xs: 2, sm: 3 }, pb: { xs: 4, sm: 6, md: 8 } }}>
          <Skeleton variant="rectangular" height={400} sx={{ borderRadius: 4, mb: 6 }} />
          <Grid container spacing={{ xs: 1, md: 4 }}>
            {[1, 2, 3, 4, 5, 6].map((item) => (
              <Grid item xs={12} sm={6} md={4} key={item}>
                <Skeleton variant="rectangular" height={300} sx={{ borderRadius: 2 }} />
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
    );
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      {/* SEO метатеги */}
      <MetaTags 
        fetchMetadata={getBlogSeo}
        defaultTitle="Блог TeleHunt - Новости и статьи о Telegram"
        defaultDescription="Читайте последние статьи и новости о Telegram в нашем блоге. Полезные советы, обзоры каналов и многое другое."
      />
      
      <Container maxWidth="lg" sx={{ pt: { xs: 2, sm: 3 }, pb: { xs: 4, sm: 6, md: 8 } }}>
        {/* Featured Article */}
        <AnimatePresence mode="wait">
          {featuredArticle && (
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 20 }}
              transition={{ duration: 0.2 }}
            >
              <Card
                onClick={() => handleArticleClick(featuredArticle)}
                sx={{
                  cursor: 'pointer',
                  display: 'flex',
                  flexDirection: { xs: 'column', md: 'row' },
                  mb: { xs: 2, sm: 3 },
                  [theme.breakpoints.down('sm')]: {
                    boxShadow: 'none',
                    borderRadius: 0,
                    borderBottom: 1,
                    borderColor: 'divider',
                    mt: 2
                  }
                }}
              >
                <CardMedia
                  component="img"
                  image={getImagePath(featuredArticle.image)}
                  alt={featuredArticle.imageAlt || featuredArticle.title}
                  sx={{
                    width: { xs: '100%', md: '50%' },
                    height: { xs: 240, md: 400 },
                    objectFit: 'cover',
                    [theme.breakpoints.down('sm')]: {
                      borderRadius: 0
                    }
                  }}
                />
                <CardContent
                  sx={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    p: { xs: 2, md: 4 },
                    '&:last-child': { pb: { xs: 2, md: 4 } }
                  }}
                >
                  <Typography component="h1" variant="h3" color="text.primary" gutterBottom>
                    {featuredArticle.title}
                  </Typography>
                  <Typography variant="subtitle1" color="text.secondary" paragraph>
                    {featuredArticle.description}
                  </Typography>
                  <Box sx={{ 
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1.5,
                    mt: 'auto'
                  }}>
                    {/* Метаданные и категория */}
                    <Box sx={{ 
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between'
                    }}>
                      {/* Левая часть: время чтения и просмотры */}
                      <Box sx={{ 
                        display: 'flex',
                        alignItems: 'center',
                        gap: 2,
                        color: 'text.secondary',
                        fontSize: { xs: '0.75rem', md: '0.8rem' }
                      }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                          <AccessTimeIcon sx={{ fontSize: '1rem' }} />
                          <span>{featuredArticle.readTime}</span>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                          <VisibilityIcon sx={{ fontSize: '1rem' }} />
                          <span>{featuredArticle.views || 0}</span>
                        </Box>
                      </Box>

                      {/* Правая часть: категория */}
                      {featuredArticle.categories?.length > 0 && (
                        <Chip
                          label={featuredArticle.categories[0]}
                          size="small"
                          sx={{ 
                            height: { xs: '20px', md: '24px' },
                            '& .MuiChip-label': {
                              px: { xs: 1, md: 1.5 },
                              fontSize: { xs: '0.7rem', md: '0.75rem' }
                            }
                          }}
                        />
                      )}
                    </Box>

                    {/* Автор */}
                    {featuredArticle.author?.username && (
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Avatar
                          src={featuredArticle.author.avatar}
                          sx={{ 
                            width: { xs: 20, md: 24 },
                            height: { xs: 20, md: 24 }
                          }}
                        >
                          {featuredArticle.author.username.charAt(0)}
                        </Avatar>
                        <Typography 
                          variant="caption"
                          sx={{ 
                            fontSize: { xs: '0.75rem', md: '0.8rem' },
                            color: 'text.secondary'
                          }}
                        >
                          {featuredArticle.author.username}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </CardContent>
              </Card>
            </motion.div>
          )}
        </AnimatePresence>

        {/* Search and Categories */}
        <Box sx={{ 
          mb: { xs: 2, sm: 3 },
          px: { xs: 1, md: 0 }
        }}>
          <SearchField
            fullWidth
            placeholder="Поиск статей..."
            value={searchTerm}
            onChange={handleSearch}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon sx={{ 
                    fontSize: { xs: '1.2rem', md: '1.5rem' }
                  }} />
                </InputAdornment>
              ),
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                height: { xs: '40px', md: '56px' }
              },
              '& .MuiOutlinedInput-input': {
                fontSize: { xs: '0.9rem', md: '1rem' }
              }
            }}
          />
          
          <Box sx={{ 
            mt: 2, 
            display: 'flex', 
            flexWrap: 'wrap', 
            gap: 1,
            justifyContent: { xs: 'center', md: 'flex-start' }
          }}>
            <CategoryChip
              label="Все"
              onClick={() => setSelectedCategory(null)}
              variant={selectedCategory === null ? "filled" : "outlined"}
              sx={{
                height: { xs: '24px', md: '28px' },
                '& .MuiChip-label': {
                  fontSize: { xs: '0.75rem', md: '0.8rem' },
                  px: { xs: 1, md: 1.5 }
                }
              }}
            />
            {allCategories.map((category) => (
              <CategoryChip
                key={category}
                label={category}
                onClick={() => setSelectedCategory(
                  selectedCategory === category ? null : category
                )}
                variant={selectedCategory === category ? "filled" : "outlined"}
                sx={{
                  height: { xs: '24px', md: '28px' },
                  '& .MuiChip-label': {
                    fontSize: { xs: '0.75rem', md: '0.8rem' },
                    px: { xs: 1, md: 1.5 }
                  }
                }}
              />
            ))}
          </Box>
        </Box>

        {/* Articles Grid */}
        <Grid 
          container 
          spacing={2} 
          sx={{ mt: { xs: 2, sm: 3 } }}
        >
          {/* Отображаем отфильтрованные статьи */}
          {displayedArticles
            .filter(article => article !== featuredArticle) // Исключаем featured статью из сетки
            .map((article, index) => (
            <Grid item xs={12} sm={6} md={4} key={article._id}>
              <motion.div
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ 
                  duration: 0.2,
                  delay: Math.min(index * 0.05, 0.3)
                }}
              >
                <StyledCard
                  onClick={() => handleArticleClick(article)}
                  sx={{ 
                    cursor: 'pointer',
                    [theme.breakpoints.down('sm')]: {
                      boxShadow: 'none',
                      borderRadius: 0,
                      borderBottom: 1,
                      borderColor: 'divider'
                    }
                  }}
                >
                  <CardMedia
                    component="img"
                    height={{ xs: 160, sm: 200 }}
                    image={getImagePath(article.image)}
                    alt={article.imageAlt || article.title}
                    loading="lazy"
                    sx={{ 
                      objectFit: 'cover',
                      [theme.breakpoints.down('sm')]: {
                        borderRadius: 0
                      }
                    }}
                  />
                  <CardContent sx={{ 
                    p: { xs: 2, md: 3 },
                    '&:last-child': { pb: { xs: 2, md: 3 } }
                  }}>
                    <Typography 
                      variant="h6" 
                      component="h2"
                      sx={{
                        fontSize: { xs: '1rem', md: '1.25rem' },
                        fontWeight: 600,
                        mb: 1,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: '-webkit-box',
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: 'vertical',
                        lineHeight: 1.4
                      }}
                    >
                      {article.title}
                    </Typography>

                    <Typography 
                      variant="body2" 
                      color="text.secondary"
                      sx={{
                        mb: 2,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: '-webkit-box',
                        WebkitLineClamp: { xs: 2, md: 3 },
                        WebkitBoxOrient: 'vertical',
                        fontSize: { xs: '0.875rem', md: '1rem' },
                        lineHeight: 1.5
                      }}
                    >
                      {article.description}
                    </Typography>

                    <Box sx={{ 
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 1.5,
                      mt: 'auto'
                    }}>
                      {/* Метаданные и категория */}
                      <Box sx={{ 
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                      }}>
                        {/* Левая часть: время чтения и просмотры */}
                        <Box sx={{ 
                          display: 'flex',
                          alignItems: 'center',
                          gap: 2,
                          color: 'text.secondary',
                          fontSize: { xs: '0.75rem', md: '0.8rem' }
                        }}>
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                            <AccessTimeIcon sx={{ fontSize: '1rem' }} />
                            <span>{article.readTime}</span>
                          </Box>
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                            <VisibilityIcon sx={{ fontSize: '1rem' }} />
                            <span>{article.views || 0}</span>
                          </Box>
                        </Box>

                        {/* Правая часть: категория */}
                        {article.categories?.length > 0 && (
                          <Chip
                            label={article.categories[0]}
                            size="small"
                            sx={{ 
                              height: { xs: '20px', md: '24px' },
                              '& .MuiChip-label': {
                                px: { xs: 1, md: 1.5 },
                                fontSize: { xs: '0.7rem', md: '0.75rem' }
                              }
                            }}
                          />
                        )}
                      </Box>

                      {/* Автор */}
                      {article.author?.username && (
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                          <Avatar
                            src={article.author.avatar}
                            sx={{ 
                              width: { xs: 20, md: 24 },
                              height: { xs: 20, md: 24 }
                            }}
                          >
                            {article.author.username.charAt(0)}
                          </Avatar>
                          <Typography 
                            variant="caption"
                            sx={{ 
                              fontSize: { xs: '0.75rem', md: '0.8rem' },
                              color: 'text.secondary'
                            }}
                          >
                            {article.author.username}
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </CardContent>
                </StyledCard>
              </motion.div>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default Blog;
