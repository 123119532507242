import React, { useState, useEffect } from 'react';
import { Container, Typography, Paper, Box, Grid, Button } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import TelegramIcon from '@mui/icons-material/Telegram';
import MetaTags from '../components/MetaTags';
import { getPageSeo } from '../services/seoApi';

const Contacts = () => {
  // Состояние для хранения SEO-метаданных
  const [seoMetadata, setSeoMetadata] = useState({
    title: 'Контакты | TeleHunt.ru',
    description: 'Свяжитесь с нами по вопросам работы каталога Telegram каналов TeleHunt.ru',
    keywords: 'контакты, обратная связь, телеграм каталог'
  });

  // Загружаем SEO-метаданные при монтировании компонента
  useEffect(() => {
    const loadSeoMetadata = async () => {
      try {
        const metadata = await getPageSeo('contacts');
        if (metadata) {
          setSeoMetadata(metadata);
        }
      } catch (error) {
        console.error('Ошибка при загрузке SEO-метаданных:', error);
      }
    };
    
    loadSeoMetadata();
  }, []);

  return (
    <>
      <MetaTags 
        metadata={seoMetadata}
        fetchMetadata={() => getPageSeo('contacts')}
        defaultTitle="Контакты | TeleHunt.ru"
        defaultDescription="Свяжитесь с нами по вопросам работы каталога Telegram каналов TeleHunt.ru"
      />
      <Container maxWidth="md" sx={{ py: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Контакты
        </Typography>
        <Paper sx={{ p: 4, mt: 3 }}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Связаться с нами
              </Typography>
              <Typography variant="body1" paragraph>
                Если у вас есть вопросы, предложения или вы хотите сообщить о проблеме, 
                вы можете связаться с нами следующими способами:
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: 2 }}>
                <EmailIcon sx={{ fontSize: 48, color: '#0088cc', mb: 2 }} />
                <Typography variant="h6" gutterBottom>
                  По электронной почте
                </Typography>
                <Button 
                  variant="contained" 
                  href="mailto:support@telehunt.ru"
                  sx={{ 
                    bgcolor: '#0088cc', 
                    '&:hover': { bgcolor: '#006699' },
                    mt: 2
                  }}
                >
                  support@telehunt.ru
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: 2 }}>
                <TelegramIcon sx={{ fontSize: 48, color: '#0088cc', mb: 2 }} />
                <Typography variant="h6" gutterBottom>
                  В Telegram
                </Typography>
                <Button 
                  variant="contained" 
                  href="https://t.me/telehunt_support"
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{ 
                    bgcolor: '#0088cc', 
                    '&:hover': { bgcolor: '#006699' },
                    mt: 2
                  }}
                >
                  @telehunt_support
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </>
  );
};

export default Contacts;
