import React from 'react';
import {
  Container,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Button,
  Box,
  Chip
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

// Пример данных для демонстрации
const mockChannels = [
  {
    id: 1,
    title: 'Канал 1',
    description: 'Описание канала 1',
    category: 'Развлечения',
    subscribers: '10K',
    image: 'https://via.placeholder.com/300x200'
  },
  {
    id: 2,
    title: 'Канал 2',
    description: 'Описание канала 2',
    category: 'Мемы',
    subscribers: '5K',
    image: 'https://via.placeholder.com/300x200'
  }
];

function ChannelList() {
  const navigate = useNavigate();
  return (
    <Box 
      sx={{ 
        minHeight: '100vh',
        bgcolor: '#f8f9fa',
        pt: 3,
        pb: 6
      }}
    >
      <Container maxWidth="lg">
        <Typography 
          variant="h4" 
          component="h1" 
          gutterBottom
          sx={{ 
            color: '#2c3e50',
            fontWeight: 600,
            mb: 4
          }}
        >
          Каталог каналов
        </Typography>

        <Grid container spacing={4}>
          {mockChannels.map((channel) => (
            <Grid item xs={12} sm={6} md={4} key={channel.id}>
              <Card onClick={() => navigate(`/channels/${channel.id}`)} style={{ cursor: 'pointer' }}>
                <CardMedia
                  component="img"
                  height="200"
                  image={channel.image}
                  alt={channel.title}
                />
                <CardContent>
                  <Typography variant="h6" component="h2" gutterBottom>
                    {channel.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary" paragraph>
                    {channel.description}
                  </Typography>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                    <Chip label={channel.category} color="primary" size="small" />
                    <Chip label={`${channel.subscribers} подписчиков`} size="small" />
                  </Box>
                  <Button 
                    variant="contained" 
                    color="primary" 
                    fullWidth
                    href="https://t.me/example"
                    target="_blank"
                  >
                    Перейти в канал
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}

export default ChannelList;
