import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { 
  Container, 
  Box,
  Grid,
  Card,
  CardContent,
  Button,
  CircularProgress,
  Alert,
  Menu,
  MenuItem,
  alpha,
  useTheme,
  Fade
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { isPrivateChannel } from '../services/urlService';
import RecommendedChannels from '../components/channelDetails/RecommendedChannels';
import RelatedChannels from '../components/channelDetails/RelatedChannels';
import MetaTags from '../components/MetaTags';
import useChannelWithSeo from '../hooks/useChannelWithSeo';
import { 
  DetailHeader, 
  DetailDescription, 
  DetailMetadata 
} from '../components/channelDetails';

/**
 * Страница деталей канала
 */
const ChannelDetails = () => {
  const { username, urlPath, identifier, id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const [shareAnchorEl, setShareAnchorEl] = useState(null);
  const [fadeIn, setFadeIn] = useState(false);
  const scrollPositionRef = useRef({});
  
  // Определяем URL-путь на основе параметров
  const channelPath = username 
    ? `/channel/@${username}` 
    : identifier 
      ? `/channel/${identifier}` 
      : urlPath 
        ? `/channel/${urlPath}` 
        : id 
          ? `/channel/id/${id}` 
          : '';
  
  // Используем идентификатор для загрузки канала
  const channelIdentifier = username || identifier || urlPath || id;
  
  // Состояние для отслеживания первой загрузки и показа индикатора
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [showLoader, setShowLoader] = useState(false);
  const [showEmptyContainer, setShowEmptyContainer] = useState(false);
  
  // Используем новый хук для получения данных канала и SEO-метаданных
  const { 
    channel, 
    recommendedChannels, 
    relatedChannels,
    seoData,
    isLoading, 
    isLoadingRecommended,
    isLoadingRelated,
    isAllDataLoaded,
    error,
    fetchChannelSeo
  } = useChannelWithSeo(channelIdentifier, channelPath);

  // Эффект для отслеживания первой загрузки
  useEffect(() => {
    if (!isLoading && isFirstLoad) {
      setIsFirstLoad(false);
    }
    
    // Добавляем небольшую задержку перед показом индикатора загрузки
    // чтобы избежать мигания при быстрой загрузке из кеша
    let loaderTimeout;
    if (isLoading) {
      if (isFirstLoad) {
        // При первой загрузке показываем индикатор сразу
        setShowLoader(true);
      } else {
        // При повторных загрузках добавляем задержку
        loaderTimeout = setTimeout(() => {
          setShowLoader(true);
        }, 300); // Увеличиваем задержку для предотвращения мигания
      }
    } else {
      setShowLoader(false);
    }
    
    return () => {
      if (loaderTimeout) clearTimeout(loaderTimeout);
    };
  }, [isLoading, isFirstLoad]);
  
  // Эффект для плавного появления контента
  useEffect(() => {
    if (!isLoading && channel) {
      // Небольшая задержка для более плавного перехода
      setFadeIn(true);
    } else {
      setFadeIn(false);
    }
  }, [isLoading, channel, location.pathname]);
  
  // Сохраняем позицию скролла при переходе между страницами
  useEffect(() => {
    const currentPath = location.pathname;
    
    // Восстанавливаем позицию скролла, если возвращаемся на страницу
    if (scrollPositionRef.current[currentPath]) {
      setTimeout(() => {
        window.scrollTo(0, scrollPositionRef.current[currentPath]);
      }, 100);
    } else {
      // Если это новая страница, скроллим наверх
      window.scrollTo(0, 0);
    }
    
    // Сохраняем позицию скролла при уходе со страницы
    return () => {
      scrollPositionRef.current[currentPath] = window.pageYOffset;
    };
  }, [location.pathname]);
  
  // Обработчики событий
  const handleShareClick = (event) => {
    if (!channel) return;
    setShareAnchorEl(event.currentTarget);
  };

  const handleShareClose = () => {
    setShareAnchorEl(null);
  };

  const handleShare = async (platform) => {
    if (!channel) return;
    handleShareClose();
    
    const url = window.location.href;
    const text = `Проверьте этот Telegram канал: ${channel.name}`;
    
    switch (platform) {
      case 'copy':
        try {
          await navigator.clipboard.writeText(url);
          // Здесь можно добавить уведомление об успешном копировании
        } catch (err) {
          console.error('Ошибка при копировании ссылки:', err);
        }
        break;
      case 'telegram':
        window.open(`https://t.me/share/url?url=${encodeURIComponent(url)}&text=${encodeURIComponent(text)}`);
        break;
      case 'twitter':
        window.open(`https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}&text=${encodeURIComponent(text)}`);
        break;
      default:
        break;
    }
  };

  // Отображаем индикатор загрузки только при первой загрузке страницы
  // и только если загрузка длится достаточно долго
  if (isLoading && showLoader && isFirstLoad) {
    return (
      <Fade in={showLoader} timeout={300}>
        <Box 
          sx={{ 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center',
            minHeight: '100vh',
            height: '100vh',
            width: '100%',
            position: 'fixed',
            top: 0,
            left: 0,
            zIndex: 1000,
            bgcolor: theme.palette.background.default
          }}
        >
          <CircularProgress size={60} thickness={4} />
        </Box>
      </Fade>
    );
  }

  // Отображаем ошибку, если не удалось загрузить канал
  if (error) {
    return (
      <Container maxWidth="lg" sx={{ py: 4, minHeight: 'calc(100vh - 200px)' }}>
        <Alert severity="error" sx={{ mb: 2 }}>
          Не удалось загрузить информацию о канале. Пожалуйста, попробуйте позже.
        </Alert>
        <Button
          startIcon={<ArrowBackIcon />}
          onClick={() => navigate(-1)}
          sx={{ mb: 3 }}
        >
          Вернуться назад
        </Button>
      </Container>
    );
  }

  // Отображаем контент страницы
  return (
    <>
      {/* SEO-метаданные */}
      <MetaTags
        metadata={seoData}
        fetchMetadata={fetchChannelSeo}
        defaultTitle={`${channel?.name || 'Канал'} - TeleHunt`}
        defaultDescription={`Подробная информация о Telegram канале ${channel?.name || ''} на TeleHunt - каталоге Telegram каналов`}
      />
      
      <Container maxWidth="lg" sx={{ py: 4, minHeight: 'calc(100vh - 200px)' }}>
        {/* Кнопка возврата */}
        <Button
          startIcon={<ArrowBackIcon />}
          onClick={() => navigate(-1)}
          sx={{ mb: 3 }}
        >
          Вернуться назад
        </Button>

        {/* Индикатор загрузки */}
        {isLoading && !isFirstLoad && showLoader && (
          <Fade in={showLoader} timeout={200}>
            <Box sx={{ 
              display: 'flex', 
              justifyContent: 'center', 
              alignItems: 'center',
              my: 4
            }}>
              <CircularProgress size={40} />
            </Box>
          </Fade>
        )}

        {/* Основной контент страницы */}
        <Fade in={!isLoading} timeout={300}>
          <Box sx={{ 
            opacity: isLoading && !isFirstLoad ? 0.5 : 1,
            transition: 'opacity 0.3s ease-in-out',
            filter: isLoading && !isFirstLoad ? 'blur(1px)' : 'none',
            pointerEvents: isLoading && !isFirstLoad ? 'none' : 'auto'
          }}>
            {/* Сообщение об ошибке */}
            {error && !isLoading && (
              <Alert severity="error" sx={{ mb: 3 }}>
                {error}
              </Alert>
            )}
            
            {/* Контент канала */}
            {!isLoading && channel && (
              <Fade in={fadeIn} timeout={400}>
                <div>
                  <Grid container spacing={3}>
                    {/* Основная информация */}
                    <Grid item xs={12} md={8}>
                      <Card sx={{ 
                        background: theme => `linear-gradient(135deg, ${alpha(theme.palette.primary.main, 0.02)} 0%, ${alpha(theme.palette.primary.light, 0.05)} 100%)`,
                        border: theme => `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
                        borderRadius: 3,
                        mb: 3,
                        overflow: 'hidden',
                        transition: 'all 0.3s ease-in-out',
                        '&:hover': {
                          boxShadow: theme => `0 8px 32px ${alpha(theme.palette.primary.main, 0.1)}`,
                          borderColor: theme => alpha(theme.palette.primary.main, 0.2)
                        }
                      }}>
                        <CardContent sx={{ 
                          p: { xs: 2, sm: 3 },
                          '& .MuiTypography-root': {
                            WebkitFontSmoothing: 'antialiased',
                            MozOsxFontSmoothing: 'grayscale',
                            textRendering: 'optimizeLegibility',
                            textShadow: '0 0 1px rgba(0,0,0,0.01)'
                          },
                          '& .MuiChip-root': {
                            WebkitFontSmoothing: 'antialiased',
                            MozOsxFontSmoothing: 'grayscale',
                            textRendering: 'optimizeLegibility',
                            fontWeight: 500
                          }
                        }}>
                          {/* Шапка канала */}
                          <DetailHeader 
                            channel={channel} 
                            onShareClick={handleShareClick} 
                          />
                          
                          {/* Описание канала */}
                          <DetailDescription channel={channel} />
                          
                          {/* Метаданные канала */}
                          <DetailMetadata 
                            channel={channel} 
                            navigate={navigate} 
                          />
                        </CardContent>
                      </Card>

                      {/* Блок с рекомендованными каналами (только для мобильных) */}
                      <Box sx={{ mt: 4, display: { xs: 'block', md: 'none' } }}>
                        <RecommendedChannels 
                          channels={recommendedChannels} 
                          isLoading={isLoadingRecommended} 
                          maxItems={4}
                        />
                      </Box>
                    </Grid>

                    {/* Сайдбар с рекомендованными каналами (только для десктопа) */}
                    <Grid item xs={12} md={4} sx={{ display: { xs: 'none', md: 'block' } }}>
                      <Box>
                        <RecommendedChannels 
                          channels={recommendedChannels} 
                          isLoading={isLoadingRecommended} 
                          maxItems={5}
                        />
                      </Box>
                    </Grid>
                  </Grid>

                  {/* Блок с похожими каналами (на всю ширину) */}
                  <Grid container sx={{ mt: 4 }}>
                    <Grid item xs={12}>
                      <RelatedChannels 
                        channels={relatedChannels} 
                        isLoading={isLoadingRelated} 
                        maxItems={6}
                      />
                    </Grid>
                  </Grid>
                </div>
              </Fade>
            )}
            {isFirstLoad && !channel && (
              <Box sx={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <CircularProgress size={40} />
              </Box>
            )}
          </Box>
        </Fade>
      </Container>

      {/* Меню для шаринга */}
      <Menu
        anchorEl={shareAnchorEl}
        open={Boolean(shareAnchorEl)}
        onClose={handleShareClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuItem onClick={() => handleShare('copy')}>Копировать ссылку</MenuItem>
        <MenuItem onClick={() => handleShare('telegram')}>Поделиться в Telegram</MenuItem>
        <MenuItem onClick={() => handleShare('twitter')}>Поделиться в Twitter</MenuItem>
      </Menu>
    </>
  );
};

export default ChannelDetails;
