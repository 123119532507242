import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams, useLocation, Link as RouterLink } from 'react-router-dom';
import axios from 'axios';
import {
  Box,
  Typography,
  Container,
  Grid,
  TextField,
  InputAdornment,
  IconButton,
  Pagination,
  Button,
  Skeleton,
  Fade,
  Paper,
  Divider
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import MetaTags from '../components/MetaTags';
import {
  ArrowBack as ArrowBackIcon,
} from '@mui/icons-material';
import {
  getCategoryBySlug, getCategoryUrl
} from '../services/categoryService';
import { getChannelUrl } from '../services/urlService';
import { getCategorySeo } from '../services/seoApi';
import ModernChannelCard from '../components/ModernChannelCard';
import PremiumChannelPromo from '../components/PremiumChannelPromo';

const CategoryChannels = () => {
  const { category: routeCategory, slug } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const queryCategory = queryParams.get('category');
  const searchFromUrl = queryParams.get('search') || '';

  const [category, setCategory] = useState(null);
  const [categoryName, setCategoryName] = useState('');
  const [channels, setChannels] = useState([]);
  const [searchTerm, setSearchTerm] = useState(searchFromUrl);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);
  const [hasPremiumInCategory, setHasPremiumInCategory] = useState(false);
  const [pagination, setPagination] = useState({
    currentPage: parseInt(queryParams.get('page')) || 1,
    totalPages: 1,
    total: 0,
    perPage: 24
  });
  const [initialLoad, setInitialLoad] = useState(true);

  // Функция получения каналов
  const fetchChannelsData = useCallback(async (page, search) => {
    if (!category) return;

    setLoading(true);
    setError(null);

    try {
      const params = {
        page,
        limit: pagination.perPage,
        category,
        search,
        ...(category !== 'Все' && { sortByPremium: true })
      };

      // Выполняем оба запроса параллельно
      const [channelsResponse, premiumResponse] = await Promise.all([
        axios.get('/api/channels', { params }),
        category !== 'Все' ? axios.get('/api/channels', {
          params: {
            category,
            isPremium: true,
            limit: 1
          }
        }) : Promise.resolve({ data: { channels: [] } })
      ]);

      setChannels(channelsResponse.data.channels);
      setPagination({
        currentPage: channelsResponse.data.pagination.currentPage,
        totalPages: channelsResponse.data.pagination.totalPages,
        total: channelsResponse.data.pagination.totalItems,
        perPage: channelsResponse.data.pagination.perPage
      });
      setHasPremiumInCategory(premiumResponse.data.channels.length > 0);
    } catch (err) {
      console.error('Error fetching data:', err);
      setError('Ошибка при загрузке каналов');
    } finally {
      setLoading(false);
      setInitialLoadComplete(true);
    }
  }, [category, pagination.perPage]);

  useEffect(() => {
    if (channels.length > 0 || initialLoadComplete) {
      setInitialLoad(false);
    }
  }, [channels, initialLoadComplete]);

  // Обработчик изменения страницы
  const handlePageChange = useCallback((e, newPage) => {
    window.scrollTo(0, 0);
    fetchChannelsData(newPage, searchTerm);
  }, [fetchChannelsData, searchTerm]);

  // Обработчик изменения поискового запроса
  const handleSearchChange = (e) => {
    const newValue = e.target.value;
    setSearchTerm(newValue);
    
    // Если поле полностью очищено, сбрасываем поиск
    if (!newValue) {
      handleClearSearch();
    }
  };

  // Обработчик отправки формы поиска
  const handleSearchSubmit = (e) => {
    e.preventDefault();
    const trimmedQuery = searchTerm.trim();
    
    // Проверяем, что запрос содержит минимум 2 символа
    if (trimmedQuery.length >= 2) {
      // Обновляем URL с поисковым запросом
      const newUrl = `${getCategoryUrl(category)}?search=${encodeURIComponent(trimmedQuery)}`;
      navigate(newUrl, { replace: true });
      fetchChannelsData(1, trimmedQuery);
    }
  };

  // Обработчик очистки поиска
  const handleClearSearch = () => {
    setSearchTerm('');
    const newUrl = getCategoryUrl(category);
    navigate(newUrl, { replace: true });
    fetchChannelsData(1, '');
  };

  // Обработчик клика по каналу
  const handleChannelClick = useCallback((channel) => {
    if (!channel) return;

    console.log('Channel click:', {
      channel,
      hasUsername: !!channel.username,
      isPrivate: channel.isPrivate
    });

    // Используем urlService для получения правильного URL канала
    const channelUrl = getChannelUrl(channel);
    navigate(channelUrl);
  }, [navigate]);

  // Эффект для обработки изменения slug
  useEffect(() => {
    // Асинхронная функция для загрузки категории
    const loadCategory = async () => {
      // Если есть slug, используем его для получения категории
      if (slug) {
        try {
          const categoryObj = await getCategoryBySlug(slug);

          if (categoryObj) {
            // Проверяем, активна ли категория
            if (!categoryObj.isActive) {
              // Если категория неактивна, перенаправляем на страницу всех категорий
              console.log('[INFO] Категория неактивна, перенаправление на страницу категорий:', categoryObj.name);
              navigate('/categories', { replace: true });
              return;
            }
            
            setCategory(categoryObj.name);
            setCategoryName(categoryObj.name);
          } else {
            // Если категория не найдена, перенаправляем на страницу 404
            navigate('/404');
          }
        } catch (error) {
          console.error('[ERROR] Ошибка при загрузке категории:', error);
          navigate('/categories', { replace: true });
        }
      }
      // Если есть routeCategory (старый формат URL), перенаправляем на новый формат
      else if (routeCategory) {
        navigate(getCategoryUrl(routeCategory), { replace: true });
      }
      // Если есть queryCategory (из query параметров), используем его
      else if (queryCategory) {
        setCategory(queryCategory);
        setCategoryName(queryCategory);
      }
      // По умолчанию используем "Все"
      else {
        setCategory('Все');
        setCategoryName('Все');
      }
    };

    // Вызываем асинхронную функцию
    loadCategory();
  }, [slug, routeCategory, queryCategory, navigate]);

  // Эффект для обработки изменения поискового запроса в URL
  useEffect(() => {
    setSearchTerm(searchFromUrl);
    fetchChannelsData(1, searchFromUrl);
  }, [searchFromUrl, fetchChannelsData]);

  // Загрузка каналов при изменении категории или страницы
  useEffect(() => {
    if (category) {
      fetchChannelsData(pagination.currentPage, searchTerm);
    }
  }, [category, pagination.currentPage, fetchChannelsData]);

  // Рендеринг скелетона загрузки
  const renderSkeletons = () => (
    <Grid container spacing={3}>
      {[...Array(6)].map((_, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <Paper 
            elevation={0}
            sx={{ 
              p: 2, 
              height: '100%', 
              borderRadius: 2,
              border: '1px solid',
              borderColor: 'divider',
              transition: 'all 0.2s',
              '&:hover': {
                borderColor: 'rgba(0, 136, 204, 0.3)',
                bgcolor: 'rgba(0, 136, 204, 0.02)',
              }
            }}
          >
            <Box sx={{ display: 'flex', mb: 1.5, alignItems: 'flex-start' }}>
              {/* Аватар */}
              <Skeleton 
                variant="rounded" 
                width={56} 
                height={56} 
                sx={{ 
                  borderRadius: 2,
                  mr: 2
                }} 
              />
              <Box sx={{ flex: 1 }}>
                {/* Название канала */}
                <Skeleton variant="text" width="85%" height={24} sx={{ mb: 0.5 }} />
                {/* Username */}
                <Skeleton variant="text" width="50%" height={18} />
              </Box>
            </Box>
            
            {/* Описание канала - только две строки */}
            <Skeleton variant="text" width="100%" height={18} sx={{ mb: 0.5 }} />
            <Skeleton variant="text" width="80%" height={18} />
            {/* Убираем нижнюю часть с чипами и подписчиками */}
          </Paper>
        </Grid>
      ))}
    </Grid>
  );

  // Рендеринг сообщения об отсутствии каналов
  const renderNoChannels = () => (
    <Fade in={true} timeout={500}>
      <Box 
        sx={{ 
          p: 3, 
          textAlign: 'center', 
          borderRadius: 2,
          maxWidth: 500,
          mx: 'auto',
          mt: 4,
          bgcolor: 'rgba(0, 136, 204, 0.04)',
          border: '1px solid rgba(0, 136, 204, 0.1)'
        }}
      >
        <Typography 
          variant="h6" 
          component="h2" 
          gutterBottom
          sx={{ 
            fontWeight: 500, 
            color: '#0088cc',
            mb: 1.5
          }}
        >
          {searchTerm 
            ? 'По вашему запросу ничего не найдено' 
            : 'В этой категории пока нет каналов'}
        </Typography>
        
        <Typography 
          variant="body2" 
          sx={{ 
            color: 'text.secondary',
            mb: 3
          }}
        >
          {searchTerm 
            ? 'Попробуйте изменить поисковый запрос или выбрать другую категорию' 
            : 'Вы можете стать первым, кто добавит канал в эту категорию!'}
        </Typography>
        
        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center', gap: 2 }}>
          {searchTerm && (
            <Button 
              variant="outlined" 
              size="small"
              onClick={handleClearSearch}
              sx={{ 
                borderRadius: 2,
                textTransform: 'none',
                fontWeight: 400
              }}
            >
              Сбросить поиск
            </Button>
          )}
          <Button 
            variant="contained" 
            size="small"
            component={RouterLink} 
            to="/categories"
            sx={{ 
              borderRadius: 2,
              bgcolor: '#0088cc',
              textTransform: 'none',
              fontWeight: 400,
              '&:hover': {
                bgcolor: '#006699'
              }
            }}
          >
            Все категории
          </Button>
        </Box>
      </Box>
    </Fade>
  );

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <MetaTags
        metadata={{
          title: `${categoryName || 'Категория'} - Telegram каналы 18+ | TeleHunt.ru`,
          description: `Лучшие Telegram каналы 18+ в категории "${categoryName || 'Категория'}". Найдите интересные каналы с эротическим контентом, сливами и приватными материалами.`,
          keywords: `telegram каналы ${typeof categoryName === 'string' ? categoryName.toLowerCase() : 'категория'}, телеграм каналы 18+ ${typeof categoryName === 'string' ? categoryName.toLowerCase() : 'категория'}, ${typeof categoryName === 'string' ? categoryName.toLowerCase() : 'категория'} каналы, сливы телеграм ${typeof categoryName === 'string' ? categoryName.toLowerCase() : 'категория'}, эротика телеграм ${typeof categoryName === 'string' ? categoryName.toLowerCase() : 'категория'}`,
          ogTitle: `${categoryName || 'Категория'} - Telegram каналы 18+ | TeleHunt.ru`,
          ogDescription: `Лучшие Telegram каналы 18+ в категории "${categoryName || 'Категория'}". Быстрый доступ к каналам для взрослых.`,
          ogImage: "/images/seo/og-image.jpg",
          canonicalUrl: `https://telehunt.ru${getCategoryUrl(categoryName || 'Все')}`,
          schema: {
            "@context": "https://schema.org",
            "@type": "CollectionPage",
            "name": `${categoryName || 'Категория'} - Telegram каналы 18+`,
            "description": `Лучшие Telegram каналы 18+ в категории "${categoryName || 'Категория'}". Найдите интересные каналы с эротическим контентом и приватными материалами.`,
            "url": `https://telehunt.ru${getCategoryUrl(categoryName || 'Все')}`,
            "isPartOf": {
              "@type": "WebSite",
              "name": "TeleHunt.ru",
              "url": "https://telehunt.ru/"
            }
          }
        }}
        fetchMetadata={() => {
          // Проверяем наличие слага перед вызовом API
          if (!slug) {
            console.log('[DEBUG] CategoryChannels - Отсутствует slug категории, используем дефолтные метаданные');
            return Promise.resolve({
              title: `${categoryName || 'Категория'} - Telegram каналы | TeleHunt.ru`,
              description: `Лучшие Telegram каналы в категории "${categoryName || 'Категория'}". Найдите интересные каналы по вашим интересам.`,
              keywords: `telegram, каналы, ${categoryName || 'категория'}, telehunt`,
              ogTitle: `${categoryName || 'Категория'} - Telegram каналы | TeleHunt.ru`,
              ogDescription: `Лучшие Telegram каналы в категории "${categoryName || 'Категория'}".`,
              ogImage: '/images/seo/og-image.jpg',
              canonicalUrl: `${window.location.origin}/category/${slug || 'all'}`
            });
          }
          // Если slug есть, вызываем API
          return getCategorySeo(slug);
        }}
        defaultTitle={`${categoryName || 'Категория'} - Telegram каналы 18+ | TeleHunt.ru`}
        defaultDescription={`Лучшие Telegram каналы 18+ в категории "${categoryName || 'Категория'}". Найдите интересные каналы с эротическим контентом, сливами и приватными материалами.`}
      />
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
        <IconButton
          onClick={() => navigate('/categories')}
          sx={{ mr: 2, color: '#0088cc' }}
          aria-label="Назад к категориям"
        >
          <ArrowBackIcon />
        </IconButton>
        <Typography
          variant="h4"
          component="h1"
          sx={{
            fontWeight: 700,
            color: '#1e293b',
            fontSize: { xs: '1.75rem', sm: '2rem' },
            letterSpacing: '-0.02em',
            lineHeight: 1.2
          }}
        >
          {categoryName || 'Загрузка категории...'}
        </Typography>
      </Box>

      <Box component="form" onSubmit={handleSearchSubmit} sx={{ mb: 4 }}>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Поиск каналов в этой категории..."
          value={searchTerm}
          onChange={handleSearchChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <IconButton 
                  onClick={handleSearchSubmit}
                  disabled={searchTerm.trim().length < 2}
                  sx={{ 
                    color: searchTerm.trim().length >= 2 ? '#0088cc' : 'text.secondary',
                    '&:hover': {
                      backgroundColor: 'rgba(0, 136, 204, 0.04)'
                    }
                  }}
                >
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
            endAdornment: searchTerm && (
              <InputAdornment position="end">
                <IconButton onClick={handleClearSearch} edge="end">
                  <ClearIcon />
                </IconButton>
              </InputAdornment>
            )
          }}
        />
      </Box>

      {error && (
        <Typography color="error" sx={{ mb: 2 }}>
          {error}
        </Typography>
      )}

      {initialLoad ? (
        renderSkeletons()
      ) : channels.length > 0 ? (
        <Fade in={true} timeout={500}>
          <Box>
            <Grid container spacing={3}>
              {/* Если категория не "Все", нет премиум-каналов, и есть обычные каналы - показываем шаблон премиум-канала */}
              {category !== 'Все' && !hasPremiumInCategory && channels.length > 0 && (
                <Grid item xs={12} sm={6} md={4}>
                  <PremiumChannelPromo onClick={() => navigate('/premium')} />
                </Grid>
              )}
              
              {channels.map((channel) => (
                <Grid item xs={12} sm={6} md={4} key={channel.id}>
                  <ModernChannelCard
                    channel={channel}
                    onClick={() => handleChannelClick(channel)}
                    currentCategory={category}
                  />
                </Grid>
              ))}
            </Grid>

            {pagination.totalPages > 1 && (
              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                <Pagination
                  count={pagination.totalPages}
                  page={pagination.currentPage}
                  onChange={handlePageChange}
                  color="primary"
                  size="large"
                  showFirstButton
                  showLastButton
                  siblingCount={1}
                  boundaryCount={1}
                  sx={{
                    '& .MuiPaginationItem-root': {
                      mx: 0.5,
                      transition: 'all 0.2s',
                      '&:hover': {
                        backgroundColor: 'rgba(25, 118, 210, 0.1)'
                      }
                    }
                  }}
                />
              </Box>
            )}
          </Box>
        </Fade>
      ) : (
        renderNoChannels()
      )}
    </Container>
  );
};

export default CategoryChannels;
