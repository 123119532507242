import React from 'react';
import { Box, Typography, Paper, Container, Button } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useNavigate } from 'react-router-dom';

const ChannelSubmitted = () => {
  const navigate = useNavigate();

  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          minHeight: '80vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          py: 4,
        }}
      >
        <Paper
          elevation={3}
          sx={{
            p: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            borderRadius: 2,
          }}
        >
          <CheckCircleOutlineIcon
            sx={{
              fontSize: 80,
              color: 'success.main',
              mb: 2,
            }}
          />
          <Typography variant="h4" component="h1" gutterBottom>
            Канал отправлен на модерацию
          </Typography>
          <Typography variant="body1" color="text.secondary" paragraph>
            Спасибо за добавление канала! Наши модераторы проверят его в ближайшее время.
            Мы уведомим вас, когда канал будет одобрен и появится в каталоге.
          </Typography>
          <Box sx={{ mt: 3 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate('/')}
              sx={{ mr: 2 }}
            >
              На главную
            </Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => navigate('/add-channel')}
            >
              Добавить еще канал
            </Button>
          </Box>
        </Paper>
      </Box>
    </Container>
  );
};

export default ChannelSubmitted;
