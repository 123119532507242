import React, { useState, useEffect, useMemo, Suspense, lazy } from 'react';
import { Link as RouterLink, useSearchParams, useParams } from 'react-router-dom'; 
import { 
  Box, 
  Container, 
  Typography, 
  Grid, 
  Card, 
  CardActionArea, 
  TextField, 
  InputAdornment,
  useTheme,
  alpha,
  Paper,
  Chip,
  Divider,
  Skeleton,
  CircularProgress
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { formatNumber, formatChannelsCount } from '../utils/formatters';
import { getCategoryUrl, getCatalogData } from '../services/categoryService'; 
import { getCategorySeo } from '../services/seoApi'; // Added import
import MetaTags from '../components/MetaTags';
import { getIconByName } from '../utils/iconRegistry';

// Компонент-заглушка для предзагрузки данных
const CategoriesLoader = () => {
  const theme = useTheme();
  return (
    <Box 
      sx={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        minHeight: '100vh', // Увеличиваем до 100vh для полного экрана
        height: '100vh', // Фиксированная высота на весь экран
        width: '100%',
        position: 'fixed', // Фиксируем позицию
        top: 0,
        left: 0,
        zIndex: 1000, // Поверх всего контента
        bgcolor: theme => theme.palette.background.default // Цвет фона как у страницы
      }}
    >
      <CircularProgress size={60} thickness={4} />
    </Box>
  );
};

// Основной компонент категорий, обернутый в Suspense
const CategoriesContent = React.memo(() => {
  const theme = useTheme();
  const [searchParams] = useSearchParams();
  // Get categorySlug from URL params
  const { categorySlug } = useParams(); 
  const [searchQuery, setSearchQuery] = useState(searchParams.get('search') || '');
  const [seoData, setSeoData] = useState(null); // Initialize seoData to null
  const [loading, setLoading] = useState(true);
  const [isReady, setIsReady] = useState(false);
  const [catalogData, setCatalogData] = useState({
    categories: [],
    popularCategories: [],
    activeCategories: [],
    filteredCategories: []
  });

  // Обновляем searchQuery при изменении URL параметров
  useEffect(() => {
    const searchFromUrl = searchParams.get('search');
    // Only update if searchFromUrl is not null to avoid clearing manual input
    if (searchFromUrl !== null) { 
      setSearchQuery(searchFromUrl);
    }
  }, [searchParams]);

  // Загружаем данные каталога и SEO при монтировании или изменении slug/запроса
  useEffect(() => {
    let mounted = true;
    
    const loadData = async () => {
      console.log(`[DEBUG] Categories.loadData - Start loading. Slug: ${categorySlug}, Query: ${searchQuery}`);
      try {
        setLoading(true);
        setIsReady(false); // Reset readiness state
        
        // --- Fetch Catalog Data ---
        const catalogPromise = getCatalogData(searchQuery).catch(err => {
          console.error('[ERROR] Categories - Failed to fetch catalog data:', err);
          return { // Return default structure on error
            categories: [], 
            popularCategories: [],
            activeCategories: [],
            filteredCategories: []
          }; 
        });

        // --- Fetch SEO Data (conditionally) ---
        let seoPromise;
        const effectiveSlug = categorySlug === 'all' ? null : categorySlug; // Treat 'all' as no specific slug
        console.log(`[DEBUG] Categories.loadData - Effective slug for SEO: ${effectiveSlug}`);

        if (effectiveSlug) {
           // Если есть конкретный slug категории, получаем SEO данные для нее
           seoPromise = getCategorySeo(effectiveSlug).catch(err => {
               console.error(`[ERROR] Categories - Не удалось получить SEO данные от API для ${effectiveSlug}:`, err);
               return null; // Return null on error to use defaults later
           });
        } else {
           // Используем дефолтные SEO данные для каталога или поиска
           seoPromise = Promise.resolve({
             title: searchQuery 
               ? `Поиск: "${searchQuery}" | TeleHunt` 
               : 'Каталог Telegram каналов и групп | TeleHunt',
             description: searchQuery 
               ? `Результаты поиска по запросу "${searchQuery}" в каталоге Telegram каналов и групп TeleHunt.` 
               : 'Найдите лучшие Telegram каналы и группы в нашем каталоге. Актуальные категории, удобный поиск.',
             keywords: searchQuery 
               ? `поиск, ${searchQuery}, telegram, каналы, группы, каталог`
               : 'каталог, telegram, каналы, группы, категории, поиск, telehunt',
             ogTitle: searchQuery 
               ? `Поиск: "${searchQuery}" | TeleHunt` 
               : 'Каталог Telegram каналов и групп | TeleHunt',
             ogDescription: searchQuery 
               ? `Результаты поиска "${searchQuery}" в каталоге TeleHunt.` 
               : 'Полный каталог Telegram каналов и групп.',
             ogImage: '/images/seo/catalog-og-image.jpg', // Default OG image
             // Исправлено: используем window.location.href для полного URL
             canonicalUrl: window.location.origin + (categorySlug ? `/category/${categorySlug}` : '/catalog') 
           });
        }

        // --- Await both promises ---
        const [catalogResult, seoResult] = await Promise.all([catalogPromise, seoPromise]);
        console.log(`[DEBUG] Categories.loadData - Promises resolved. Catalog result: ${catalogResult ? 'OK' : 'FAIL'}, SEO result:`, seoResult); // Log SEO result content

        if (mounted) {
          // --- Update Catalog State ---
          setCatalogData({
            categories: catalogResult?.categories || [],
            popularCategories: catalogResult?.popularCategories || [],
            activeCategories: catalogResult?.activeCategories || [],
            allCategories: catalogResult?.allCategories || [],
            filteredCategories: catalogResult?.filteredCategories || []
          });
          
          // --- Update SEO State with Logging ---
          if (seoResult) {
            console.log('[DEBUG] Categories.loadData - SEO result is TRUTHY, setting SEO data.');
            console.log('[INFO] Categories - Установлены SEO-метаданные:', {
              title: seoResult.title,
              description: seoResult.description ? seoResult.description.substring(0, 50) + '...' : 'отсутствует',
              hasOgData: !!(seoResult.ogTitle || seoResult.ogDescription || seoResult.ogImage)
            });
             // Исправлено: устанавливаем канонический URL из полученных SEO-данных или генерируем его
             const finalSeoData = { 
               ...seoResult, 
               canonicalUrl: seoResult.canonicalUrl || window.location.href 
             };
            setSeoData(finalSeoData);
          } else {
            console.log('[DEBUG] Categories.loadData - SEO result is FALSY, setting fallback SEO data.');
            // Fallback if seoResult is null (e.g., fetch error and no defaults were applicable)
            console.warn('[WARN] Categories - Не удалось получить SEO-метаданные, используются минимальные значения.');
            setSeoData({ 
              title: 'Каталог | TeleHunt', 
              description: 'Каталог Telegram каналов и групп TeleHunt.',
              keywords: 'каталог, telegram, каналы, группы',
              ogTitle: 'Каталог | TeleHunt', 
              ogDescription: 'Каталог Telegram каналов и групп TeleHunt.',
              ogImage: '/images/seo/catalog-og-image.jpg',
              canonicalUrl: window.location.href
            });
          }
          
          setLoading(false);
          // Delay readiness slightly
          setTimeout(() => {
            if (mounted) setIsReady(true);
          }, 50); 
        }
      } catch (error) {
        // Catch any unexpected error from Promise.all or state updates
        console.error('[ERROR] Categories - Общая ошибка при загрузке данных:', error);
        if (mounted) {
          console.log('[DEBUG] Categories.loadData - Caught error, setting loading/ready state and minimal SEO.');
          setLoading(false);
          setIsReady(true); // Become ready even on error to show content
          // Set minimal SEO on error
          setSeoData({ 
            title: 'Ошибка загрузки каталога | TeleHunt', 
            description: '',
            canonicalUrl: window.location.href 
          }); 
        }
      }
    };
    
    loadData();
    
    return () => {
      mounted = false;
    };
  // Update when slug or search query changes
  }, [categorySlug, searchQuery]); 

  // Рендеринг скелетонов для карточек категорий
  const renderCategorySkeletons = (count = 5) => (
    <Grid container spacing={2}>
      {[...Array(count)].map((_, index) => (
        <Grid item xs={12} sm={6} md={2.4} key={`skeleton-${index}`}>
          <Card 
            elevation={0}
            sx={{ 
              bgcolor: 'rgba(0, 0, 0, 0.03)',
              border: '1px solid',
              borderColor: 'rgba(0, 0, 0, 0.08)',
              height: 110
            }}
          >
            <Box sx={{ height: '100%', p: 2.5 }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'space-between' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                  <Skeleton variant="circular" width={24} height={24} sx={{ mr: 1.5 }} />
                  <Skeleton variant="text" width="70%" height={24} />
                </Box>
                <Skeleton variant="text" width="50%" height={20} />
              </Box>
            </Box>
          </Card>
        </Grid>
      ))}
    </Grid>
  );

  // If SEO data is not yet available (initial load or error), show loader
  if (!seoData || loading) { // Simplified condition: show loader if no SEO or still loading
    return <CategoriesLoader />;
  }

  // Determine categories to show based on search query
  const categoriesToShow = searchQuery 
    ? catalogData.filteredCategories 
    : catalogData.categories;

  return (
    <Box sx={{ 
      display: 'flex', 
      flexDirection: 'column', 
      minHeight: '100vh'
      // Убираем opacity и transition, чтобы вернуть прежний вид
      // opacity: isReady ? 1 : 0, 
      // transition: 'opacity 0.3s ease-in-out'
    }}>
      {/* Восстанавливаем MetaTags с прежними props */}
      <MetaTags 
        metadata={seoData} 
        defaultTitle="Каталог Telegram каналов | TeleHunt.ru"
        defaultDescription="Найдите лучшие Telegram каналы в нашем каталоге по категориям и интересам"
        // fetchMetadata больше не нужен, т.к. загрузка идет в useEffect
      />
      
      <Container 
        maxWidth="lg" 
        sx={{ 
          py: 4, 
          flexGrow: 1,
          minHeight: '80vh' // Возвращаем прежний minHeight
          // Убираем opacity и transition
        }}
      >
        {/* Возвращаем прежний заголовок и поиск */}
        <Box sx={{ mb: 6 }}>
          <Typography variant="h4" component="h1" gutterBottom>
            Категории каналов
          </Typography>
          <Typography variant="subtitle1" color="text.secondary" gutterBottom>
            Найдите интересные каналы по категориям
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Поиск по категориям..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            sx={{ mt: 2, maxWidth: 600 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Box>

        {/* Возвращаем прежний блок популярных категорий */}
        <Box 
          sx={{ 
            mb: 6,
            minHeight: loading ? '200px' : 'auto', 
            display: { 
              xs: searchQuery ? 'none' : 'block', 
              sm: 'block' 
            }
          }}
        >
          <Typography variant="h6" gutterBottom sx={{ mb: 3 }}>
            Популярные категории
          </Typography>
          {loading ? renderCategorySkeletons(5) : (
            <Grid container spacing={2}>
              {catalogData.popularCategories.map((category) => {
                const Icon = getIconByName(category.icon);
                return (
                  <Grid item xs={12} sm={6} md={2.4} key={category.slug || category.id}>
                    <Card 
                      elevation={0}
                      sx={{ 
                        bgcolor: alpha(category.color || theme.palette.grey[200], 0.1),
                        border: '1px solid',
                        borderColor: alpha(category.color || theme.palette.grey[400], 0.2),
                        height: 110
                      }}
                    >
                      <CardActionArea
                        component={RouterLink}
                        to={getCategoryUrl(category.slug)}
                        sx={{ height: '100%', p: 2.5 }}
                      >
                        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'space-between' }}>
                          <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                            <Icon sx={{ 
                              color: category.color || theme.palette.text.secondary, 
                              mr: 1.5,
                              fontSize: '1.5rem'
                            }} />
                            <Typography 
                              variant="subtitle1" 
                              sx={{ 
                                color: 'text.primary',
                                fontWeight: 500,
                                lineHeight: 1.3
                              }}
                            >
                              {category.name}
                            </Typography>
                          </Box>
                          <Typography 
                            variant="body2" 
                            color="text.secondary"
                          >
                            {formatChannelsCount(category.channelsCount || 0)}
                          </Typography>
                        </Box>
                      </CardActionArea>
                    </Card>
                  </Grid>
                );
              })}
            </Grid>
          )}
        </Box>
        
        <Divider sx={{ mb: 4 }} />
        
        {/* Возвращаем прежний блок всех категорий */}
        <Box sx={{ minHeight: loading ? '400px' : 'auto' }}>
          <Typography variant="h6" gutterBottom sx={{ mb: 3 }}>
            {searchQuery ? 'Результаты поиска' : 'Все категории'}
          </Typography>
          {loading ? renderCategorySkeletons(10) : (
            <Grid container spacing={2}>
              {categoriesToShow
                .filter(cat => cat.slug !== 'all') // Исключаем "Все", если она есть
                .sort((a, b) => {
                  const countDiff = (b.channelsCount || 0) - (a.channelsCount || 0);
                  return countDiff !== 0 ? countDiff : a.name.localeCompare(b.name);
                })
                .map((category) => {
                  const Icon = getIconByName(category.icon);
                  return (
                    <Grid item xs={12} sm={6} md={3} key={category.slug || category.id}>
                      <Paper
                        elevation={0}
                        sx={{
                          p: 2.5,
                          height: 110, 
                          border: '1px solid',
                          borderColor: 'divider',
                          transition: 'all 0.2s',
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'space-between',
                          '&:hover': {
                            borderColor: category.color || theme.palette.primary.main,
                            bgcolor: alpha(category.color || theme.palette.primary.light, 0.05),
                          }
                        }}
                        component={RouterLink}
                        to={getCategoryUrl(category.slug)}
                        style={{ textDecoration: 'none' }}
                      >
                        <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                          <Icon sx={{ 
                            color: category.color || theme.palette.text.secondary, 
                            mr: 1.5,
                            fontSize: '1.5rem',
                            mt: 0.3 
                          }} />
                          <Typography 
                            variant="subtitle1" 
                            sx={{ 
                              color: 'text.primary',
                              fontWeight: 500,
                              lineHeight: 1.3,
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              display: '-webkit-box',
                              WebkitLineClamp: 2,
                              WebkitBoxOrient: 'vertical'
                            }}
                          >
                            {category.name}
                          </Typography>
                        </Box>
                        <Typography 
                          variant="body2" 
                          color="text.secondary"
                        >
                          {formatChannelsCount(category.channelsCount || 0)}
                        </Typography>
                      </Paper>
                    </Grid>
                  );
              })}
              
              {!loading && categoriesToShow.length === 0 && (
                <Box 
                  sx={{ 
                    width: '100%', 
                    py: 5, 
                    display: 'flex', 
                    flexDirection: 'column', 
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <Typography variant="h6" color="text.secondary" gutterBottom>
                    {searchQuery ? 'Категории не найдены' : 'Нет доступных категорий'}
                  </Typography>
                  {searchQuery && (
                    <Typography variant="body2" color="text.secondary">
                      Попробуйте изменить поисковый запрос
                    </Typography>
                  )}
                </Box>
              )}
            </Grid>
          )}
        </Box>
      </Container>
    </Box>
  );
});

// Wrapper component with Suspense fallback
const Categories = () => (
  <Suspense fallback={<CategoriesLoader />}>
    <CategoriesContent />
  </Suspense>
);

export default Categories;