import React, { useState, useEffect } from 'react';
import {
  Container,
  Paper,
  Typography,
  TextField,
  Button,
  Box,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Snackbar,
  Alert,
  InputAdornment,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
  Avatar,
  IconButton,
  FormHelperText,
  CircularProgress
} from '@mui/material';
import {
  Add as AddIcon,
  Close as CloseIcon,
  Title as TitleIcon,
  Link as LinkIcon,
  Description as DescriptionIcon,
  Category as CategoryIcon,
  CloudUpload as CloudUploadIcon,
  ArrowBack as ArrowBackIcon,
  Telegram as TelegramIcon,
  SmartToy as SmartToyIcon,
  Chat as ChatIcon,
  Info as InfoIcon
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import CategorySelect from '../components/CategorySelect';
import MetaTags from '../components/MetaTags';
import { getCreateChannelSeo } from '../services/seoApi';

const categories = [
  'News',
  'Entertainment',
  'Technology',
  'Sports',
  'Education',
  'Business',
  'Art',
  'Music',
  'Humor',
  'Other'
];

const MAX_NAME_LENGTH = 100;
const MAX_DESCRIPTION_LENGTH = 500;
const MAX_IMAGE_SIZE = 5 * 1024 * 1024; // 5MB в байтах

// Функция для проверки на ссылки
const containsLinks = (str) => {
  const urlRegex = /(https?:\/\/[^\s]+)|(www\.[^\s]+)|([^\s]+\.[a-zA-Z]{2,})/gi;
  return urlRegex.test(str);
};

const styles = {
  formSection: {
    backgroundColor: '#f8f9fa',
    padding: '24px',
    borderRadius: '8px',
    marginBottom: '24px',
    border: '1px solid #e0e0e0'
  },
  sectionTitle: {
    color: '#2c3e50',
    fontWeight: 600,
    fontSize: '1.1rem',
    marginBottom: '16px'
  },
  resourceTypeContainer: {
    display: 'flex',
    gap: '12px',
    marginBottom: '24px'
  },
  resourceTypeButton: {
    flex: 1,
    padding: '12px',
    border: '2px solid #e0e0e0',
    borderRadius: '8px',
    cursor: 'pointer',
    transition: 'all 0.2s ease',
    backgroundColor: '#ffffff',
    '&:hover': {
      backgroundColor: '#f0f7ff',
      borderColor: '#0088cc'
    },
    '&.selected': {
      backgroundColor: '#e3f2fd',
      borderColor: '#0088cc',
      color: '#0088cc'
    }
  }
};

const CreateChannel = () => {
  // Состояние для хранения SEO-метаданных
  const [seoMetadata, setSeoMetadata] = useState({
    title: 'Добавить канал | TeleHunt.ru',
    description: 'Добавьте свой Telegram канал в каталог TeleHunt.ru',
    keywords: 'добавить канал, телеграм каталог, новый канал'
  });

  // Загружаем SEO-метаданные при монтировании компонента
  useEffect(() => {
    const loadSeoMetadata = async () => {
      try {
        const metadata = await getCreateChannelSeo();
        if (metadata) {
          setSeoMetadata(metadata);
        }
      } catch (error) {
        console.error('Ошибка при загрузке SEO-метаданных:', error);
      }
    };
    
    loadSeoMetadata();
  }, []);
  
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    link: '',
    description: '',
    category: '',
    type: 'channel',
    avatar: null,
    categories: []
  });
  const [errors, setErrors] = useState({});
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [imagePreview, setImagePreview] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Проверка аутентификации
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      setSnackbarSeverity('error');
      setSnackbarMessage('Please log in to create a channel');
      setOpenSnackbar(true);
      setTimeout(() => navigate('/login'), 2000);
      return;
    }

    // Проверка валидности токена
    const checkAuth = async () => {
      try {
        await axios.get('/api/users/me', {
          headers: { Authorization: `Bearer ${token}` }
        });
      } catch (error) {
        if (error.response?.status === 401) {
          setSnackbarSeverity('error');
          setSnackbarMessage('Your session has expired. Please log in again.');
          setOpenSnackbar(true);
          localStorage.removeItem('token');
          setTimeout(() => navigate('/login'), 2000);
        } else {
          // Если ошибка не связана с аутентификацией, продолжаем работу
          console.error('Non-auth error:', error);
        }
      }
    };

    // Запускаем проверку токена каждые 5 минут
    checkAuth();
    const interval = setInterval(checkAuth, 5 * 60 * 1000);

    return () => clearInterval(interval);
  }, [navigate]);

  const handleChange = (e) => {
    console.log('Изменение поля формы:', e.target.name, e.target.value);
    
    const { name, value } = e.target;
    let newValue = value;

    if (name === 'link' && value && !value.startsWith('https://t.me/')) {
      if (value.startsWith('t.me/')) {
        newValue = 'https://' + value;
      } else if (!value.includes('t.me/')) {
        newValue = 'https://t.me/' + value.replace(/^\/+/, '');
      }
    }

    setFormData(prev => ({
      ...prev,
      [name]: newValue
    }));

    validateField(name, newValue);
  };

  const handleImageChange = (e) => {
    console.log('handleImageChange started');
    const file = e.target.files[0];
    if (file) {
      console.log('File selected:', file.name, file.size, file.type);
      
      if (file.size > MAX_IMAGE_SIZE) {
        console.log('File too large');
        setErrors(prev => ({
          ...prev,
          avatar: 'Image size should not exceed 5MB'
        }));
        return;
      }

      const reader = new FileReader();
      reader.onloadend = () => {
        console.log('FileReader completed');
        setImagePreview(reader.result);
        setFormData(prev => {
          console.log('Updating formData with new avatar');
          return {
            ...prev,
            avatar: file
          };
        });
        // Убираем ошибку аватара при успешной загрузке
        setErrors(prev => {
          const newErrors = { ...prev };
          delete newErrors.avatar;
          return newErrors;
        });
      };
      reader.readAsDataURL(file);
    }
  };

  const handleRemoveImage = () => {
    console.log('Removing image');
    setImagePreview(null);
    setFormData(prev => ({
      ...prev,
      avatar: null
    }));
  };

  const validateField = (name, value) => {
    let newErrors = { ...errors };

    switch (name) {
      case 'name':
        if (!value.trim()) {
          newErrors.name = 'Name is required';
        } else if (value.length > MAX_NAME_LENGTH) {
          newErrors.name = `Name should not exceed ${MAX_NAME_LENGTH} characters`;
        } else {
          delete newErrors.name;
        }
        break;

      case 'description':
        if (!value.trim()) {
          newErrors.description = 'Description is required';
        } else if (value.length > MAX_DESCRIPTION_LENGTH) {
          newErrors.description = `Description should not exceed ${MAX_DESCRIPTION_LENGTH} characters`;
        } else if (containsLinks(value)) {
          newErrors.description = 'Description cannot contain links';
        } else {
          delete newErrors.description;
        }
        break;

      case 'link':
        if (!value) {
          newErrors.link = 'Link is required';
        } else if (!value.startsWith('https://t.me/')) {
          newErrors.link = 'Link should be a valid Telegram channel link';
        } else {
          delete newErrors.link;
        }
        break;

      case 'categories':
        if (!value || value.length === 0) {
          newErrors.categories = 'At least one category must be selected';
        } else {
          delete newErrors.categories;
        }
        break;

      default:
        break;
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Form submission started');
    console.log('Current form state:', formData);
    
    setIsSubmitting(true);

    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('Необходимо войти в систему');
      }

      console.log('Creating FormData');
      const formDataToSend = new FormData();
      
      // Добавляем все поля формы
      Object.keys(formData).forEach(key => {
        if (key === 'categories') {
          formData[key].forEach(category => {
            formDataToSend.append('categories[]', category);
          });
        } else if (key === 'avatar' && formData[key]) {
          console.log('Adding avatar to FormData:', formData[key].name);
          formDataToSend.append('avatar', formData[key]);
        } else {
          formDataToSend.append(key, formData[key]);
        }
      });

      console.log('Sending request');
      const response = await axios.post('/api/channels', formDataToSend, {
        headers: { 
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'multipart/form-data'
        }
      });

      console.log('Request successful:', response.data);
      setSnackbarSeverity('success');
      setSnackbarMessage('Канал успешно создан!');
      setOpenSnackbar(true);

      setTimeout(() => {
        navigate('/channel-submitted', { 
          state: { 
            channelName: formData.name,
            channelId: response.data._id
          }
        });
      }, 2000);

    } catch (error) {
      console.error('Error submitting form:', error);
      setSnackbarSeverity('error');
      setSnackbarMessage(error.response?.data?.message || error.message);
      setOpenSnackbar(true);
    } finally {
      console.log('Form submission completed');
      setIsSubmitting(false);
    }
  };

  const handleCategoriesChange = (newValue) => {
    setFormData(prev => ({
      ...prev,
      categories: newValue
    }));
    if (errors.categories) {
      setErrors(prev => ({
        ...prev,
        categories: undefined
      }));
    }
  };

  return (
    <Box
      sx={{
        minHeight: '100vh',
        bgcolor: '#f8f9fa',
        position: 'relative',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          height: '300px',
          background: 'linear-gradient(135deg, #0088cc 0%, #005580 100%)',
          zIndex: 0
        },
        '&::after': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          height: '300px',
          background: 'radial-gradient(circle at 80% 20%, rgba(255,255,255,0.1) 0%, transparent 60%)',
          zIndex: 0
        }
      }}
    >
      {/* SEO метатеги */}
      <MetaTags 
        title={seoMetadata.title}
        description={seoMetadata.description}
        keywords={seoMetadata.keywords}
        structuredData={seoMetadata.structuredData}
      />
      
      <Container maxWidth="md" sx={{ py: 4, position: 'relative', zIndex: 1 }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            mb: 4,
            color: 'white'
          }}
        >
          <ArrowBackIcon
            sx={{
              mr: 1,
              cursor: 'pointer',
              '&:hover': {
                opacity: 0.8
              }
            }}
            onClick={() => navigate(-1)}
          />
          <Typography variant="h4" component="h1">
            Create New Channel
          </Typography>
        </Box>
        <Paper 
          component="form"
          onSubmit={handleSubmit}
          sx={{ 
            p: 4, 
            backgroundColor: 'white',
            borderRadius: '16px',
            boxShadow: '0 4px 20px rgba(0,0,0,0.1)'
          }}
        >
          {/* Channel Details Section */}
          <Box sx={styles.formSection}>
            <Typography sx={styles.sectionTitle}>Channel Details</Typography>
            <Grid container spacing={3}>
              {/* Name Field */}
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="name"
                  label="Channel Name"
                  value={formData.name}
                  onChange={handleChange}
                  error={!!errors.name}
                  helperText={errors.name || `${formData.name.length}/${MAX_NAME_LENGTH}`}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <TitleIcon sx={{ color: '#0088cc' }} />
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    sx: { color: 'rgba(0, 0, 0, 0.7)' }
                  }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      backgroundColor: '#ffffff',
                      '&:hover fieldset': {
                        borderColor: '#0088cc',
                        borderWidth: 2
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#0088cc',
                        borderWidth: 2
                      },
                      '& input': {
                        color: '#2c3e50',
                        '&:-webkit-autofill': {
                          WebkitBoxShadow: '0 0 0 1000px white inset',
                          WebkitTextFillColor: '#2c3e50',
                          caretColor: '#2c3e50'
                        }
                      }
                    },
                    '& .MuiInputBase-input': {
                      color: '#2c3e50',
                    },
                    '& .MuiFormHelperText-root': {
                      color: 'rgba(0, 0, 0, 0.45)',
                      fontWeight: 400
                    },
                    '& .MuiInputLabel-root': {
                      color: 'rgba(0, 0, 0, 0.7)'
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'rgba(0, 0, 0, 0.23)',
                      borderWidth: '1px',
                    }
                  }}
                />
              </Grid>

              {/* Link Field */}
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="link"
                  label="Telegram Link"
                  value={formData.link}
                  onChange={handleChange}
                  error={!!errors.link}
                  helperText={errors.link || 'Example: https://t.me/yourchannel'}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LinkIcon sx={{ color: '#0088cc' }} />
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    sx: { color: 'rgba(0, 0, 0, 0.7)' }
                  }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      backgroundColor: '#ffffff',
                      '&:hover fieldset': {
                        borderColor: '#0088cc',
                        borderWidth: 2
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#0088cc',
                        borderWidth: 2
                      },
                      '& input': {
                        color: '#2c3e50',
                        '&:-webkit-autofill': {
                          WebkitBoxShadow: '0 0 0 1000px white inset',
                          WebkitTextFillColor: '#2c3e50',
                          caretColor: '#2c3e50'
                        }
                      }
                    },
                    '& .MuiInputBase-input': {
                      color: '#2c3e50',
                    },
                    '& .MuiFormHelperText-root': {
                      color: 'rgba(0, 0, 0, 0.45)',
                      fontWeight: 400
                    },
                    '& .MuiInputLabel-root': {
                      color: 'rgba(0, 0, 0, 0.7)'
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'rgba(0, 0, 0, 0.23)',
                      borderWidth: '1px',
                    }
                  }}
                />
              </Grid>

              {/* Description Field */}
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  multiline
                  rows={4}
                  name="description"
                  label="Description"
                  value={formData.description}
                  onChange={handleChange}
                  error={!!errors.description}
                  helperText={errors.description || `${formData.description.length}/${MAX_DESCRIPTION_LENGTH} (No links allowed)`}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <DescriptionIcon sx={{ color: '#0088cc' }} />
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    sx: { color: 'rgba(0, 0, 0, 0.7)' }
                  }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      backgroundColor: '#ffffff',
                      '&:hover fieldset': {
                        borderColor: '#0088cc',
                        borderWidth: 2
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#0088cc',
                        borderWidth: 2
                      },
                      '& input': {
                        color: '#2c3e50',
                        '&:-webkit-autofill': {
                          WebkitBoxShadow: '0 0 0 1000px white inset',
                          WebkitTextFillColor: '#2c3e50',
                          caretColor: '#2c3e50'
                        }
                      }
                    },
                    '& .MuiInputBase-input': {
                      color: '#2c3e50',
                    },
                    '& .MuiFormHelperText-root': {
                      color: 'rgba(0, 0, 0, 0.45)',
                      fontWeight: 400
                    },
                    '& .MuiInputLabel-root': {
                      color: 'rgba(0, 0, 0, 0.7)'
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'rgba(0, 0, 0, 0.23)',
                      borderWidth: '1px',
                    }
                  }}
                />
              </Grid>

              {/* Categories Field */}
              <Grid item xs={12}>
                <CategorySelect
                  value={formData.categories}
                  onChange={handleCategoriesChange}
                  error={!!errors.categories}
                  helperText={errors.categories}
                  categories={categories}
                />
              </Grid>
            </Grid>
          </Box>

          {/* Resource Type */}
          <FormControl component="fieldset" fullWidth error={!!errors.type}>
            <Box sx={styles.resourceTypeContainer}>
              {['Channel', 'Bot', 'Chat'].map((type) => (
                <Box
                  key={type}
                  onClick={() => handleChange({ target: { name: 'type', value: type.toLowerCase() } })}
                  sx={{
                    ...styles.resourceTypeButton,
                    color: '#2c3e50',
                    ...(formData.type === type.toLowerCase() && {
                      backgroundColor: '#e3f2fd',
                      borderColor: '#0088cc',
                      color: '#0088cc'
                    })
                  }}
                >
                  <Typography align="center" sx={{ fontWeight: 500, color: 'inherit' }}>{type}</Typography>
                </Box>
              ))}
            </Box>
            {errors.type && (
              <FormHelperText error>{errors.type}</FormHelperText>
            )}
          </FormControl>

          {/* Avatar Upload */}
          <Box sx={styles.formSection}>
            <Typography sx={styles.sectionTitle}>Channel Avatar</Typography>
            <Box
              sx={{
                border: '2px dashed #0088cc',
                borderRadius: 2,
                p: 3,
                textAlign: 'center',
                cursor: 'pointer',
                backgroundColor: '#ffffff',
                '&:hover': {
                  backgroundColor: 'rgba(0, 136, 204, 0.04)',
                  boxShadow: '0 2px 8px rgba(0, 136, 204, 0.15)'
                }
              }}
              onClick={() => document.getElementById('avatar-input').click()}
            >
              {imagePreview ? (
                <Box sx={{ position: 'relative', display: 'inline-block' }}>
                  <Avatar
                    src={imagePreview}
                    sx={{
                      width: 120,
                      height: 120,
                      mb: 2,
                      boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)'
                    }}
                  />
                  <IconButton
                    size="small"
                    sx={{
                      position: 'absolute',
                      top: -8,
                      right: -8,
                      backgroundColor: '#fff',
                      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                      '&:hover': {
                        backgroundColor: '#f5f5f5'
                      }
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleRemoveImage();
                    }}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </Box>
              ) : (
                <>
                  <CloudUploadIcon sx={{ fontSize: 48, color: '#0088cc', mb: 1 }} />
                  <Typography variant="body1" sx={{ mb: 1, color: '#1a1a1a', fontWeight: 500 }}>
                    Click to upload avatar
                  </Typography>
                  <Typography variant="body2" sx={{
                    color: '#2c3e50',
                    opacity: 0.7,
                    fontWeight: 400
                  }}>
                    PNG, JPG or GIF (max. 5MB)
                  </Typography>
                </>
              )}
              <input
                id="avatar-input"
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                style={{ display: 'none' }}
              />
            </Box>
          </Box>

          {/* Action Buttons */}
          <Box 
            sx={{ 
              mt: 3, 
              display: 'flex', 
              flexDirection: { xs: 'column', sm: 'row' },
              gap: { xs: 2, sm: 0 }
            }}
          >
            <Button
              variant="outlined"
              color="primary"
              onClick={() => navigate('/moderation-rules')}
              startIcon={<InfoIcon />}
              sx={{ 
                mr: { xs: 0, sm: 2 },
                width: { xs: '100%', sm: 'auto' }
              }}
            >
              Правила модерации
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => navigate(-1)}
              startIcon={<ArrowBackIcon />}
              sx={{ 
                width: { xs: '100%', sm: 'auto' }
              }}
            >
              Отмена
            </Button>
          </Box>
          <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center' }}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              type="submit"
              disabled={isSubmitting}
              sx={{
                minWidth: 200,
                height: 48,
                fontSize: '1.1rem',
                position: 'relative',
                width: { xs: '100%', sm: 'auto' }
              }}
            >
              {isSubmitting ? (
                <>
                  <CircularProgress
                    size={24}
                    sx={{
                      position: 'absolute',
                      left: '50%',
                      marginLeft: '-12px'
                    }}
                  />
                  <span style={{ marginLeft: '24px' }}>Отправка...</span>
                </>
              ) : (
                'Создать канал'
              )}
            </Button>
          </Box>
        </Paper>

        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={() => setOpenSnackbar(false)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert
            onClose={() => setOpenSnackbar(false)}
            severity={snackbarSeverity}
            sx={{ width: '100%' }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Container>
    </Box>
  );
};

export default CreateChannel;
